  import React from 'react'
  import Layout from '../../../components/App/Layout'
  import Navbar from '../../../components/App/NavbarRV'
  import Footer from '../../../components/App/FooterRV'
  import KDPImagePage from '../../../assets/images/pages/3-16-0-0.jpg'
  
  const Details = () => {
      return (
          <Layout>
              <Navbar />
              <div>
                  <div className='container'>
                      <div className='page-title-content'>
                          <ul>
<li><a href='/'>Accueil</a></li><li><a href='/paca/'>Trouvez un expert près de chez vous</a></li><li>Expert humidité Bouches-du-Rhône</li>
                          </ul>
                      </div>
                  </div>
              </div>
              <section className="services-details-area ptb-100">
                  <div className="container">
                      <div className="row">
                          <div className="col-lg-8 col-md-12">
                              <div className="services-details-desc">
                                
<h1>Pourquoi faire réaliser une expertise humidité ?</h1>
<p> </p>
<div className='services-details-image'>
  <img src={KDPImagePage} alt="Expert humidité Bouches-du-Rhône" />
</div>

<p>Des problématiques récurrentes d’humidité, salpêtre, moisissures dans votre logement ou votre immeuble ? </p>

<p>Des solutions existent. Si ces problèmes persistent, l’intervention d’un Expert humidité est fortement conseillé</p>


<h2>Rôle de l’expert humidité</h2>

<p>L’Expert humidité est un professionnel indépendant impartial, qui vous aide à déterminer les causes et origines des problèmes d’humidité afin de vous orienter sur les solutions les plus adaptées pour éradiquer de façon pérenne les problèmes d’humidité dans votre logement.</p>

<p>Pour effectuer son diagnostic, il utilise un matériel spécialisé permettant d’identifier les sources d’humidité.  </p>

<p>Nos experts humidité disposent des compétences techniques nécessaires pour identifier les signes et analyser les causes de l’humidité. Ils peuvent également éclairer sur les solutions réparatrices pouvant être envisagées et au besoin dégager des responsabilités.</p>


<p></p>
<div className="others-option d-flex align-items-center">
    <div className="option-item">
        <a class="default-btn" href="/contact/"><i className="flaticon-right"></i> Contacter un expert humidité</a>
    </div>
</div>
<p> </p>


<p> </p>



                                
                              </div>
                          </div>
                          <div className="col-lg-4 col-md-12">
                              <div className="services-details-info">
                                  <ul className="services-list">
                                      
<li><a href='/paca/expert-humidite-bouche-rhone/' className='active'>Expert humidité Bouches-du-Rhône</a></li>
                                      
                                  </ul>
                                  <a href='javascript:history.go(-1)'>Retour</a>
                              </div>
                          </div>
                      </div>
                  </div>
              </section>
              <Footer />
          </Layout>
      );
  }

  export default Details